import React from "react";
import { graphql, navigate, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import News from "../components/news-archive";
import { ru_RU } from "../locale/ru_RU";

import { Grid, Cell } from "baseui/layout-grid";
import { LocaleProvider } from "baseui";
import { Pagination, SIZE } from "baseui/pagination";
import { Block } from "baseui/block";
import { Button, KIND } from "baseui/button";

const NewsPage = ({
  data: { allNewsArchive },
  pageContext: { numPages, currentPage },
}) => {
  const [curPage, setCurrentPage] = React.useState(currentPage);
  return (
    <Layout>
      <SEO title="Архив новостей" description='Архив новостей Фонда' />
      <Grid>
        <Cell alignItems="center" span={[4, 8, 8]}>
          <News data={allNewsArchive} />
          <LocaleProvider locale={ru_RU}>
            <Pagination
              size={SIZE.compact}
              numPages={numPages}
              currentPage={curPage}
              onPageChange={({ nextPage }) => {
                const pageNumber = Math.min(Math.max(nextPage, 1), numPages);
                setCurrentPage(pageNumber);
                nextPage === 1
                  ? navigate(`/archive`)
                  : navigate(`/archive/page/${pageNumber}`);
              }}
            />
          </LocaleProvider>
        </Cell>
        <Cell alignItems="center" span={[4, 8, 4]}>
          <Block marginTop="30px">
            <Button $as={Link} to="/news" kind={KIND.tertiary}>
              Новости
            </Button>
          </Block>
        </Cell>
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allNewsArchive(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        title
        description
        date(formatString: "DD MMMM YYYY", locale: "ru")
        slug
      }
    }
  }
`;

export default NewsPage;
