import React from "react";

import { Heading, HeadingLevel } from "baseui/heading";

import NewsCard from "./newsCard";

const News = ({ data: { nodes } }) => {
  const listArchiveNews = nodes.map(news => (
    <NewsCard
      key={news.id.toString()}
      date={news.date}
      title={news.title}
      description={news.description}
      link={`${news.slug}`}
      tag={news.tag ? news.tag.toUpperCase() : "МКК"}
    />
  ));
  return (
    <HeadingLevel>
      <Heading>Архив новостей</Heading>
      <HeadingLevel>{listArchiveNews}</HeadingLevel>
    </HeadingLevel>
  );
};

export default News;
